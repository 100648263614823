import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics"; 

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "dijitalkartvizitim-6c360.firebaseapp.com",
  databaseURL: "https://businesscard-update-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dijitalkartvizitim-6c360",
  storageBucket: "dijitalkartvizitim-6c360.appspot.com",
  messagingSenderId: "692707580688",
  appId: "1:692707580688:web:799fd6e6b2d68840b3e395",
  measurementId: "G-J6R765YPN9"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
