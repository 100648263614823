import "./admin.scss";
import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import {
  collection,
  doc,
  serverTimestamp,
  getFirestore,
  updateDoc,
  setDoc,
  getDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import {  storage } from "../../firebase";
import Navbar from "../../components/navbar/Navbar";

const UpdateAllUser = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userUID, setuserUID] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState('');
  const auth = getAuth();
  const db = getFirestore();
  const functions = getFunctions();
  const sendEmail = httpsCallable(functions, 'sendEmail');

  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const userEmail= email;
      const userPassword= password;
      const emailHtml = `
      <html>
      <head>
        <style>
          .email-body { font-family: Arial, sans-serif; }
          .email-header { background-color: #f4f4f4; padding: 10px; text-align: center; }
          .email-content { padding: 20px; }
          .email-footer { background-color: #f4f4f4; padding: 10px; text-align: center; }
        </style>
      </head>
      <body>
        <div class="email-body">
          <div class="email-header">
            <img src="http://dijitalkartvizitim.com/images/logo-login.png" style="width: 250px;" alt="Dijital Kartvizitim Logo" />
          </div>
          <div class="email-content">
            <h2>Dijital Kartvizitim Kullanıcı Giriş Bilgileriniz</h2>
            <p>Merhaba,</p>
            <p>DijitalKartvizitim Giriş bilgileriniz aşağıdadır:</p>
            <p><strong>Mail:</strong> ${userEmail}</p>
            <p><strong>Şifre:</strong> ${userPassword}</p>
            <p>Giriş yapmak için <a href="https://www.dijitalkartvizitim.com/login">buraya tıklayın</a>.</p>
          </div>
          <div class="email-footer">
            <p>&copy; ${new Date().getFullYear()} DijitalKartvizitim</p>
          </div>
        </div>
      </body>
      </html>
      `;
      //yeni kullanıcı oluşturma

      await setDoc(doc(db, 'users', user.uid), {
        email: email,
        createdAt: new Date().toISOString(),
        logo:"",        img:"",        aboutusa:"",        address:"",        amazon:"",
        bankIban:"",        bankName:"",        bankRecipient:"",        catalog:"",
        catalogtext:"",        company:"",        email:"",        facebook:"",
        hepsiburada:"",       instagram:"",        job:"",        linkedin:"",
        name:"",        note:"",        officalAddress:"",        officialName:"",
        officialVAT:"",officalAddress2:"",        officialName2:"",
        officialVAT2:"",        phone:"",        sahibinden:"",        surname:"",
        tiktok:"",        trendyol:"",        twitter:"",        uid:user.uid,
        userLink:"",        vcfLink:"",        website:"",        websitetext:"", template:"box",
        youtube:"", backgroundColor:"#343434", textColor:"#e3e7e5", iconColor:"#343434", iconBoxColor:"#e3e7e5",
      });
      setSuccess('Kullanıcı başarıyla oluşturuldu');
      setuserUID(user.uid);
      sendEmail({ to: userEmail, subject: 'Dijital Kartvizitim Kullanıcı Giriş Bilgileri', text: emailHtml  })
      .then((result) => {
        console.log("Mail gönderme Başarılı");
      })
      .catch((error) => {
        console.log("Mail gönderme Başarısız");
        // Hata işleme
      });


      console.log('User created and added to Firestore');
    } catch (err) {
      setError(err.message);
    }




  };

  //tüm kullanıcılara yeni veri ekleme
  async function addFieldToAllUsers() {
    // 'users' koleksiyonundaki tüm dokümanları getir
    const querySnapshot = await getDocs(collection(db, 'users'));
  
    // Her bir dokümana yeni alanı ekleyin
    querySnapshot.forEach((document) => {
      const userDoc = doc(db, 'users', document.id);
      updateDoc(userDoc, {
        template: "box",
      }).then(() => {
        console.log(`User ${document.id} updated successfully.`);
      }).catch((error) => {
        console.log(`Error updating user ${document.id}: `, error);
      });
    });
  };
  async function updateVCardsForAllUsers() {
    try {
      // Tüm kullanıcıları çek
      const querySnapshot = await getDocs(collection(db, "users"));
      for (const doc of querySnapshot.docs) {
        const userId = doc.id;
        await updateVCardForUser(userId);
      }
    } catch (error) {
      console.error("Tüm kullanıcılar için VCard güncelleme hatası: ", error);
    }
  }
  
  async function updateVCardForUser(userId) {
    const userRef = doc(db, "users", userId);
    try {
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        const vcard = generateVCardFromUserData(userData);
        if (!vcard) return; // Telefon numarası yoksa işlemi atla
  
        const file = new Blob([vcard], { type: "text/vcard" });
        const filename = `${userData.name}${userData.surname}.vcf`;
        const storageRef = ref(storage, `/${userId}/${filename}`);
  
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        await updateDoc(userRef, { vcfLink: url });
  
        console.log("VCard güncellendi: ", url);
      } else {
        console.log("Belirtilen UID'ye sahip kullanıcı bulunamadı.");
      }
    } catch (error) {
      console.error("VCard güncelleme hatası: ", error);
    }
  }
function generateVCardFromUserData(userData) {
  // Telefon numarası kontrolü
  if (!userData.phone) {
    console.log("Telefon numarası bulunamadı, VCard oluşturulamıyor.");
    return null; // Telefon numarası yoksa, fonksiyonu sonlandır
  }

  let vcard = "BEGIN:VCARD\n";
  vcard += "VERSION:3.0\n";

  // İsim ve soyisim
  if (userData.name && userData.surname) {
    vcard += `FN:${userData.name} ${userData.surname}\n`;
    vcard += `N:${userData.surname};${userData.name}\n`;
  } else if (userData.name) {
    vcard += `FN:${userData.name}\n`;
  }

  // Telefon
  vcard += `TEL;TYPE=CELL:${userData.phone}\n`;

  // E-posta
  if (userData.email) {
    vcard += `EMAIL:${userData.email}\n`;
  }

  // Web sitesi
  if (userData.website) {
    vcard += `URL:${userData.website}\n`;
  }

  // Şirket ve iş
  if (userData.company || userData.job) {
    vcard += `ORG:${userData.company}`;
    if (userData.job) {
      vcard += `;${userData.job}`;
    }
    vcard += "\n";
  }

  // Not
  if (userData.note) {
    vcard += `NOTE:${userData.note}\n`;
  }

  // Sosyal medya profilleri
  const socialMediaTypes = {
    facebook: "facebook",
    instagram: "instagram",
    linkedin: "linkedin",
    tiktok: "tiktok",
    youtube: "youtube",
    twitter: "twitter"
  };

  for (const [key, type] of Object.entries(socialMediaTypes)) {
    if (userData[key]) {
      vcard += `X-SOCIALPROFILE;TYPE=${type}:${userData[key]}\n`;
    }
  }

  vcard += "END:VCARD";
  return vcard;
}


async function cleanUserData() {
  try {
    // Kullanıcılar koleksiyonundaki tüm belgeleri al
    const querySnapshot = await getDocs(collection(db, "users"));

    querySnapshot.forEach(async (document) => {
      const userData = document.data();
      let needsUpdate = false;

      // Tüm anahtar/değer çiftlerini kontrol et
      for (const key in userData) {
        if (userData[key] === " ") {
          userData[key] = ""; // Boşluk olan değerleri boş string ile değiştir
          needsUpdate = true;
        }
      }

      // Eğer güncelleme gerekiyorsa, belgeyi güncelle
      if (needsUpdate) {
        await updateDoc(doc(db, "users", document.id), userData);
        console.log(`Kullanıcı güncellendi: ${document.id}`);
      }
    });
  } catch (error) {
    console.error("Kullanıcı verilerini temizleme hatası: ", error);
  }
}
const generateRandomPassword = () => {
  const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!.";
  let password = '';
  for (let i = 0; i < 10; i++) {
    password += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return password;
};

const handleGeneratePassword = () => {
  const newPassword = generateRandomPassword();
  document.getElementById("password").value=newPassword;
  setPassword(newPassword);
};

function convertToLatin(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    .replace(/ğ/g, "g").replace(/Ğ/g, "G")
    .replace(/ç/g, "c").replace(/Ç/g, "C")
    .replace(/ş/g, "s").replace(/Ş/g, "S")
    .replace(/ı/g, "i").replace(/İ/g, "I")
    .replace(/ö/g, "o").replace(/Ö/g, "O")
    .replace(/ü/g, "u").replace(/Ü/g, "U");
}

async function getAllUsernames() {
  const usernames = new Set();
  const querySnapshot = await getDocs(collection(db, "users"));
  querySnapshot.forEach((doc) => {
    const userData = doc.data();
    if (userData.username) {
      usernames.add(userData.username);
    }
  });
  return usernames;
}

async function generateUniqueUsername(baseUsername, existingUsernames) {
  let uniqueUsername = baseUsername;
  let counter = 1;

  while (existingUsernames.has(uniqueUsername)) {
    uniqueUsername = baseUsername + counter;
    counter++;
  }

  return uniqueUsername;
}

async function updateAllUsernames() {
  try {
    const existingUsernames = await getAllUsernames();
    const querySnapshot = await getDocs(collection(db, "users"));

    for (const doc of querySnapshot.docs) {
      const userData = doc.data();
      if (userData.name && userData.surname) {
        const baseUsername = convertToLatin((userData.name + userData.surname).toLowerCase()).replace(/\s+/g, '');
        const uniqueUsername = await generateUniqueUsername(baseUsername, existingUsernames);
        existingUsernames.add(uniqueUsername);
        const userRef = doc.ref;

        await updateDoc(userRef, { username: uniqueUsername });

        console.log(`Kullanıcı ${userRef.id} için benzersiz username güncellendi: `, uniqueUsername);
      }
    }
  } catch (error) {
    console.error("Kullanıcı adı güncelleme hatası: ", error);
  }
}



  return (
    <div className="fullpage">
      <Navbar></Navbar>
      <div className="login">
        <form onSubmit={handleCreateUser}>
          <input
            type="email"
            placeholder="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            placeholder="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
          />
           <button type="button" onClick={handleGeneratePassword}>Rastgele Şifre Oluştur</button>
          <button type="submit">Yeni kullanıcı Kaydet</button>
          {error && <span>{error}</span>}
          {success && <span style={{ color: "#036208" }}>{success}</span>}
          {success && <span style={{ color: "#036208" }}>Profil : www.dijitalkartvizitim.com/{userUID}</span>}
          
        </form>



      </div>
      {/* ... other parts of your component */}



    </div>
  );
};

export default UpdateAllUser;
