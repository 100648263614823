 import "./navbar.scss";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import { auth, db, storage } from "../../firebase";
import { Navigate, useNavigate } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation, I18nextProvider } from 'react-i18next';
import { useEffect } from "react";
import { doc, getDoc, getFirestore } from "firebase/firestore";

import React, { useState } from "react";
import { Link } from "react-router-dom"; // Sayfalar arası geçişler için
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
const Navbar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const navitage = useNavigate();
  const { t, i18n  } = useTranslation();
  const [currentUser, setCurrentUser] = useState(null);
  const browserLanguage = navigator.language.substring(0, 2);

  const [isOpen, setIsOpen] = useState(false); // Hamburger menü durumunu tutacak state

  // Hamburger menüyü aç/kapat
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    // 👇️ only runs once

    i18n.changeLanguage(browserLanguage);
    const select = document.getElementById("language-select");
    select.value = browserLanguage;
    console.log(select.value);



  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  function handleSignOut() {
    auth
      .signOut()
      .then(() => {
        console.log("Çıkış yapıldı!");
        localStorage.removeItem("user");
        navitage("/login");
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function navigateToProfile(){
     const uid = auth.currentUser.uid;
    window.open("/"+uid ,'_blank');
  }
  function navigateToUpdatePassword(){
   window.location.assign("/updatepassword");
 }
 function navigateToAnalysis(){
  window.location.assign("/profileanalytics");
}
function navigateToAdmin(){
  window.location.assign("/admin");
}
async function redirectToUserProfile() {
  const uid = auth.currentUser.uid;
  const userRef = doc(db, "users", uid);

  try {
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const userData = docSnap.data();
      const username = userData.username;
      if (username) {
        window.open("/" + username, '_blank');
      } else {
        console.log("Kullanıcı adı bulunamadı.");
      }
    } else {
      console.log("Kullanıcı bilgisi bulunamadı.");
    }
  } catch (error) {
    console.error("Kullanıcı profil sayfasına yönlendirme hatası: ", error);
  }
}
 function navigateToUpdateUser(){
  window.location.assign("/updateuser");
}
  
  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    i18n.changeLanguage(newLanguage);
  };

  return (
    <div className="navbar">
        <nav>
      <div className="logo">
        <Link to="/updateuser"><img src="/images/logo-navbar.png"/></Link>
      </div>
      <ul className={`menu ${isOpen ? "open" : ""}`}>

        <div className="items">
        <div className="item">
        <div className="language-select-div">
        <select id="language-select" onChange={handleLanguageChange}>
          <option value="en">EN</option>
          <option value="de">DE</option>
          <option value="tr">TR</option>
          <option value="ru">RU </option>
        </select>
      </div>
        </div>
        {currentUser && currentUser.uid === 'AEq8yyVZDcSEQyNIdATmy2XBO762' && (
        <div className="item">
          {/* Admin kullanıcılar için ekstra menü öğeleri */}
            <button onClick={navigateToAdmin} className="">
              Admin
            </button>
          {/* Diğer admin menü öğeleri */}
        </div>
      )}
        <div className="item">
            <button onClick={navigateToUpdateUser} className="">
            {t('navbar.updatePage')}
            </button>
          </div>
        <div className="item">
            <button onClick={navigateToUpdatePassword} className="">
            {t('navbar.passwordPage')}
            </button>
          </div>
          <div className="item">
            <button onClick={navigateToAnalysis} className="">
            {t('navbar.analysis')}
            </button>
          </div>
        <div className="item">
            <button onClick={redirectToUserProfile} className="" >
            {t('navbar.viewprofile')}
            </button>
          </div>
          <div className="item">
            <button onClick={handleSignOut} className="Signout">
            {t('navbar.logout')}
              <ExitToAppIcon className="icon" />
            </button>
          </div>
        </div>
      </ul>
      <div className="menu-icon" onClick={toggleMenu}>
        <i className={`fas fa-${isOpen ? "times" : "bars"}`}><MenuOutlinedIcon/></i>
      </div>
    </nav>
      <div className="wrapper">


      </div>
    </div>



  );
};

export default Navbar;
